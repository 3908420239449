import React, { useEffect } from "react"
import * as myStyles from "./404.module.css"
import Layout from "../components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql, useStaticQuery } from 'gatsby'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsTranslation(locale: {eq: "en"}) {
        home
        mattresses
        aboutUs
        guides
        countries
        contactUs
        cookiePrivacyPolicy
        accept
        learnMore
        company
        region

        altDetails
        altExternalLink
        altFlag
        altHero
        altIconCheck
        altIconDown
        altIconPlus
        altIconTimes
        altLogo
        altPortrait
        altPromotion
        activateDarkMode
        activateLightMode
      }
    }
  `)

  useEffect(() => {
    if (window !== undefined) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'event': '404-page-visited' })
    }
  }, [])

  return (
    <Layout
      locale="en"
      queryTranslation={data.datoCmsTranslation}
    >
      <Seo
        title="404: Not found"
        description="Page unavailable"
        image={null}
        pathname="404"
      />

      <div className="relative h-screen bg-zone-1">
        <div className={myStyles.notFound}>
          <div className={myStyles.notFoundInner}>
            <h1>Oops!</h1>
          </div>
          <h2
            className="font-grotesk uppercase mt-0 text-zone-1 text-4xl font-bold mb-4"
          >
            404 - Page not found
          </h2>
          <p
            className="mb-5 mt-0 text-lg font-signika text-zone-2"
          >
            The page you are looking for might have been removed had its name changed or is temporarily unavailable.
          </p>
          <a
            href="/"
            className="rounded-3xl uppercase font-grotesk no-underline bg-zone-14 inline-block py-4 px-10 text-white font-semibold transition duration-300 hover:text-zone-1 hover:bg-zone-12"
          >
            Go To Homepage
          </a>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage